<template>
  <div class="filter-container large-width-padding noselect">
    <div class="filter" v-click-outside="dismiss">
      <div class="filter-header">
        <div class="filter-year">
          <p>{{ $t('news.filter-year') }}:</p>
          <div class="text-hoverable filter-selection" @click="changeFocus(0)">
            <p class="filter-current">{{years[this.$store.state.filterYear]}}</p>
            <back-icon class="filter-button" :class="{'filter-button-active': focus === 0}"/>
          </div>
          <ul class="filter-menu" :class="{'filter-menu-active': focus === 0}">
            <div v-for="(item, index) in years" :key="index"> 
              <li class="text-hoverable" @click="selectYearItem(index)">{{item}}</li>
            </div>
          </ul>
        </div>

        <div class="filter-order">
          <p>{{ $t('news.filter-order') }}:</p>
          <div class="text-hoverable filter-selection" @click="changeFocus(1)">
            <p class="filter-current">{{order[this.$store.state.filterOrder]}}</p>
            <back-icon class="filter-button" :class="{'filter-button-active': focus === 1}"/>
          </div>
          <ul class="filter-menu" :class="{'filter-menu-active': focus === 1}">
            <div v-for="(item, index) in order" :key="index"> 
              <li class="text-hoverable" @click="selectOrderItem(index)">{{item}}</li>
            </div>
          </ul>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import BackIcon from '../../assets/icons/back_white.svg'

export default {
  components: {
    BackIcon,
  },
  props: {
    years: [],
    order: [],
  },
  data() {
    return {
      focus: -1,
    }
  },
  methods: {
    changeFocus(index) {
      if(this.focus === index) this.focus = -1
      else this.focus = index
    },
    dismiss() {
      this.changeFocus(-1)
    },
    selectYearItem(index) {
      this.selectedYear = index
      this.focus = -1
      this.$store.state.filterYear = index
    },
    selectOrderItem(index) {
      this.selectedOrder = index
      this.focus = -1
      this.$store.state.filterOrder = index
    }
  }
}
</script>

<style>
.filter-container {
  position: relative;
  z-index: 10;
  /* max-width: 1000px;
  width: 90%;
  margin: 0 auto; */
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}

.filter {
  display: flex;
  justify-content: flex-end;
}

.filter-header {
  position: relative;
  display: flex;
  background-color: #e5b409;
  height: 30px;
  align-items: center;
  border-radius: 15px;
}

.filter-year, .filter-order {
  position: relative;
  display: flex;
  margin: 0 20px;
  column-gap: 10px;
  align-items: center;
}

.filter-year p, .filter-order p {
  margin: 0;
  color: white;
}

.filter-selection {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.filter-menu {
  position: absolute;
  background-color: #e5b409;
  z-index: -1;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.075);
  padding: 0 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0 0 10px 10px;
  top: 0px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease;
}
.filter-menu-active {
  visibility: visible;
  opacity: 1;
  top: 25px;
}
.filter-menu li {
  color: white;
  text-decoration: none;
  list-style-type: none;
  font-size: 20px;
  font-weight: lighter;
}


p.filter-current {
  font-weight: lighter;
}

.filter-button {
  transform: rotate(-90deg);
  transition: transform 200ms ease;
}

.filter-button-active {
  transform: rotate(90deg);
}

@media screen and (max-width: 700px) {
  .filter-container {
    justify-content: center;
  }
}

</style>