<template>
  <div>
    <image-header :text="$t('news.image-header')">
      <!-- 9, 10?, 11?, 12 -->
      <img style="object-position: center" src="@/assets/header_images/11.jpeg">
    </image-header>
    <content-sheet>
      <news-filter :years="yearsC" 
                   :order="orderC"/>

      <!-- <p>{{ $store.state }}</p> -->
      <div class="news-list">
        <div v-for="entry in newsList" :key="entry.time">
          <news-list-entry :data="entry" />
        </div>
      </div>
      
        <p v-if="Object.keys(newsList).length === 0">{{ $t('news.no-news') }}</p>
      
    </content-sheet>
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import ImageHeader from '../components/ImageHeader.vue'
import NewsListEntry from '../components/news/NewsListEntry.vue'
import NewsFilter from '../components/news/NewsFilter.vue'

import moment from 'moment'

export default {
  components: {
    ContentSheet,
    ImageHeader,
    NewsListEntry,
    NewsFilter
  },
  metaInfo() {
    return {
      title: this.$t('navbar.news')
    }
  },
  data() {
    return {
      orderVal: [true, false],
    }
  },
  computed: {
    yearsC() {
      const years = []
      for(const n in this.$store.state.news) {
        const y = moment(n).year()
        if(!years.includes(y)) years.push(y)
      }
      years.sort((a,b) => b - a)
      years.unshift(this.$t('news.filter-all'))

      return years
    },
    orderC() {
      return [this.$t('news.filter-latest'), this.$t('news.filter-oldest')]
    },
    newsList() {
      var keys = Object.keys(this.$store.state.news)

      keys = keys.filter((key) => {
        if(this.$i18n.locale !== 'sv') {
          const article = this.$store.state.news[key]
          return article.title[1]
        } else {
          const article = this.$store.state.news[key]
          return article.title[0]
        }
        return true
      })

      if(this.yearsC[this.$store.state.filterYear] > -1) {
        keys = keys.filter(key => moment(key).format('YYYY') == this.yearsC[this.$store.state.filterYear])
      }

      keys.sort((a, b) => {
        if (a < b)
          return this.orderVal[this.$store.state.filterOrder] ? 1 : -1
        if (a > b)
          return this.orderVal[this.$store.state.filterOrder] ? -1 : 1
        return 0
      }) 

      const news = {}
      for(const key of keys) news[key] = this.$store.state.news[key]

      return news
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>

</style>