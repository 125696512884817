<template>
  <div>
    <div class="large-width-padding">
      <router-link :to="data.slug" append>
        <article class="news-list-container">
          <img v-if="data.images[0]" :src="data.images[0]" class="news-list-image">
          <div class="text-left" style="display: flex; flex-direction: column; align-items: flex-start; cursor: pointer">
            <h3 class="text--primary text-hoverable" style="margin: 0; font-size: 24px">{{ data.title[+($i18n.locale !== 'sv')] }}</h3>
            <p class="text-light" style="text-transform: capitalize; margin: 0">{{ date }}</p>
            <p class="news-list-item-content cap4" style="white-space: pre-wrap">{{ plain }}</p>
            <p class="text-bold text--primary text-hoverable" style="margin: 0; font-size: 16px">{{ $t('news.read-more') }}</p>
          </div>
        </article>
      </router-link>
      <div class="divider" style="background-color: #E6E6E6; max-width: 1000px; margin: 20px auto"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    data: {},
  },
  computed: {
    date() {
      moment.locale(this.$i18n.locale)
      return moment(this.data.time).utc().format('MMM DD, YYYY')
    },
    plain() {
      let html = this.data.content[+(this.$i18n.locale !== 'sv')].replaceAll('<br>',' ')
      // let html = this.data.content[+(this.$i18n.locale !== 'sv')].replaceAll('<br>',' ').replaceAll(/(<\/.{1,2}>{1})/g, '\n$1')
      let div = document.createElement("div");
      div.innerHTML = html;
      const plaintext = div.innerText;

      return plaintext
    }
  }
}
</script>

<style>

.news-list-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 0 auto;
  max-width: 1000px;
  box-sizing: border-box; */
}

.news-list-item-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-list-image {
  min-width: none;
  width: 190px;
  height: 190px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 30px;
}

.cap4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .news-list-container {
    flex-direction: column;
  }

  .news-list-image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
  }
}

</style>